<div class="alert-container">
  <div class="" *ngIf="loading">
    <spiner spinColor="#ffff00"></spiner>
  </div>

  <div class="pop-windows box-shadow animated bounceInUp" *ngIf="!loading" [ngStyle]="{'border': '2px solid black'}">

    <div class="head-windows" [ngStyle]="{'background-color': msgIcon.colorcont}">

    </div>
    
    <div class="icon-container" [ngStyle]="{'color': msgIcon.color}">
      <i class="fa fa-{{ msgIcon.type }} faicon" [ngStyle]="{'color':  'black'}"></i>
    </div> 

    <div class="message">
      <span>
        {{alert.message}}
      </span>
    </div>

    <button 
      class="action-button" 
      (mouseleave)="btn_hover = false" 
      (mouseenter)="btn_hover = true" 
      (click)="emitResponse()"
      [ngStyle]="{'border': '2px solid black', 'color': btn_hover ? '#fff' : 'black', 'font-weight': '700'}" 
      [hidden]="!btn">
      {{ 'ACEPTAR' }}
    </button>
  </div>

</div>