import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertService } from 'src/app/shared-components/alert.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { UsersService } from 'src/app/services/users/users.service';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  _opened: boolean = true;
  modalRef: BsModalRef | null;
  user_data: any = {}
  public menu: boolean = true;
  resizeimg: any;
  movile: boolean = false
  user_rol: string = ''
  pfn: boolean = false
  root: boolean = false
  report: boolean = true;
  datos: any = {
    f_password: null,
    password: null,
    c_password: null
  }
  msg: string = ''
  valid: boolean = false;

  constructor(
    private _router: Router,
    private _active: ActivatedRoute,
    private _auth: AuthService,
    private _alert: AlertService,
    private modalService: BsModalService,
    private _users: UsersService,
    
  ) {

    this._auth.checksession().subscribe((res: any) => {
      this.user_rol = res.data.role.name
      this.user_data = res.data

      if (res.data.role.context){

        if (res.data.role.context.pfn == 'root'){
          this.root = true
          this.user_data.rol = 'Administrador'
        }
        if (res.data.role.context.pfn == 'reports'){
          this.report = false
          this.user_data.rol = 'Control Sox'
        }
        if (res.data.role.context.pfn == "regionals"){
          this.user_data.rol = 'Director'
        }
        if (res.data.role.context.pfn == "managements"){
          this.user_data.rol = 'Gerente'
        }
        if (res.data.role.context.pfn == "business_units"){
          this.user_data.rol = 'Supervisor'
        }
        this.pfn = true
        sessionStorage.setItem('pfn', '1')
      }
    }, error => {
      if (error.error.status == 'EXPIRED_TOKEN'){
        this._alert.setOpen({ open: true, message: 'Su sesión ha finalizado', level: 'danger' });
      }
      if (error.error.message == 'INVALID_CREDENTIALS' || error.error.message == 'NO_AUTH_CREDENTIALS'){
        this._alert.setOpen({ open: true, message: 'Sus credenciales son invalidas', level: 'danger' });
      }
      sessionStorage.clear();
      localStorage.clear();
      this._router.navigate(['/login']);
      return false;
    });
    
   }

  ngOnInit(): void {
    this.valid_device();
    if (sessionStorage.getItem('access_token') == null){
      this._router.navigate(['/login']);
    }

    
  }

  FuntionResize(event){
    this.resizeimg = event.target.innerWidth ;
    
  }

  private valid_device() {
    let ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.movile = true;
      this._opened = false
      
    } else {
      this.movile = false;
    }
  }

  public close(){
    this._auth.logout()
  }

  public home() {
    this._router.navigate(['/home'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public users(){
    this._router.navigate(['users-data'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public signature(){
    this._router.navigate(['credit-signature'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public requests(){
    this._router.navigate(['requests-data'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public historical_updates(){
    this._router.navigate(['historical-updates-data'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public historical_requests(){
    this._router.navigate(['historical-requests'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public requests_updates(){
    this._router.navigate(['requests-updates-data'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public credits_summary(){
    this._router.navigate(['credit-summary'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public documentation_credits(){
    this._router.navigate(['documentation-credits'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  //pfn feature
  public amount(){
    this._router.navigate(['amount-administrator'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public reason(){
    this._router.navigate(['reason-administrator'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public release(){
    this._router.navigate(['pfn-release'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public reports(){
    this._router.navigate(['reports'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  public users_administration(){
    this._router.navigate(['users-administrator'], { relativeTo: this._active });
    if (this.movile == true){
      this._opened = false
    }
  }

  _modeNum: number = 1;
  _positionNum: number = 0;
  _dock: boolean = false;
  _closeOnClickOutside: boolean = false;
  _closeOnClickBackdrop: boolean = false;
  _showBackdrop: boolean = false;
  _animate: boolean = true;
  _trapFocus: boolean = true;
  _autoFocus: boolean = true;
  _keyClose: boolean = false;
  _autoCollapseHeight: number = null;
  _autoCollapseWidth: number = null;

  _toggleSidebar(): void {
    this._opened = !this._opened;
  }

  mode(){
    if (this.movile == true){
      return 'over'
    }
    if (this.resizeimg <= 550){
      return 'over'
    }
    return 'push'
  }

  
  _toggleAutoCollapseHeight(): void {
    this._autoCollapseHeight = this._autoCollapseHeight ? null : 500;
  }

  _toggleAutoCollapseWidth(): void {
    this._autoCollapseWidth = this._autoCollapseWidth ? null : 500;
  }

  openModal(template: TemplateRef<any>) {
    //this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-dialog-centered modal-md' });
    this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
  }

  openModal2(template2: TemplateRef<any>) {
    this.modalService.hide(1);
    //this.modalRef = this.modalService.show(template2, { id: 2, class: 'modal-dialog-centered modal-md' });
    this.modalRef = this.modalService.show(template2, { id: 2, class: 'modal-md', backdrop: 'static' });
  }

  modalOK(form: NgForm, template3: TemplateRef<any>) {
    if (form.valid){
      if (this.valid){
        this._alert.setOpen({ open: true, message: 'Las contraseñas deben coincidir', level: 'danger' });
        return
      }
      let data = {
        old_password: this.datos.f_password,
        new_password: this.datos.password
      }
      this._users.updatePassword(this.datos).subscribe(
        (res) => {
          this.modalService.hide(2);
          this.datos = {
            f_password: null,
            password: null,
            c_password: null
          }
          setTimeout(() => {
            this.modalRef = this.modalService.show(template3, { id: 3, class: 'modal-dialog-centered modal-md', backdrop: 'static' });
          }, 500);
        },
        error => {
          if (error.error.localized_code == 'INVALID_PASSWORD'){
            this._alert.setOpen({ open: true, message: 'debe ingresar una contraseña valida', level: 'danger' });
          }
          if (error.error.localized_code == 'INVALID_CURRENT_PASSWORD'){
            this._alert.setOpen({ open: true, message: 'Su contraseña actual no es correcta', level: 'danger' });
          }
        }
      )
    } else {
      this._alert.setOpen({ open: true, message: 'Debe llenar correctamente los datos del formulario', level: 'danger' });
      return false;
    }
    //this.modalRef = this.modalService.show(template3, { id: 3, class: 'modal-md' });
  }

  closeModal(num, template: null){
    this.modalService.hide(num);
    if (num == 2){
      this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    }
  }
}
