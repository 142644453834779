import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { environment } from '../../../environments/environment';
import { constants } from '../../../environments/constants';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor( 
    public httpclient: HttpClient, 
    private storage: StorageService ) {
  }

  getUsers() {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.get<any>(`${environment.backendUrl + constants.config.getUsers}`, { headers: header });
  }

  getUsersFilter(data, page, page_size) {
    let _data = {
      "filters": {}
    }
    _data["filters"]["by_context"] = "pfn"
    if (data['role']){
      if (data['role'] != 'all'){
        _data["filters"]['by_role'] = data['role']
      }
    }
    
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.post(`${environment.backendUrl + constants.config.filterUsers + '?page=' + page + '&page_size=' + page_size }`, 
    _data
    , { headers: header });
  }

  getUserbyId(id) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.get<any>(`${environment.backendUrl + constants.config.getUserbyId + '/' + id}`, { headers: header });
  }

  createUser(data) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.post(`${environment.backendUrl + constants.config.saveUsers }`, { 
      user_name: data.name,
      email: data.email,
      password: data.password,
      role_uuid: data.role
    }, { headers: header });
  }

  createUserPFN(data) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.post(`${environment.backendUrl + constants.config.saveUsers }`,  
      data
    , { headers: header });
  }

  updateUser(id, data) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.put(`${environment.backendUrl + constants.config.updateUsers + '/' + id  }`, { 
      updates: {
        user_name: data.user,
        email: data.email,
        password: data.password,
        role_uuid: data.role
      }
    }, { headers: header });
  }

  updatePassword(data) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.put(`${environment.backendUrl + constants.config.updatePassword }`, { 
      old_password: data.f_password,
      new_password: data.password
    
    }, { headers: header });
  }

  updateUserPFN(id, data) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.put(`${environment.backendUrl + constants.config.updateUsers + '/' + id  }`, { 
      updates: {
        user_name: data.user,
        email: data.email,
        password: data.password,
        role_uuid: data.role,
        auth_context: data.data
      }
    }, { headers: header });
  }

  updateStatusUser(id, status) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.put(`${environment.backendUrl + constants.config.updateUsers + '/' + id  }`, { 
      updates: {
        status: status
      }
    }, { headers: header });
  }

  deleteUser(id) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.delete(`${environment.backendUrl + constants.config.deleteUsers + '/' + id }`, { headers: header });
  }

  getRoles(){
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.get<any>(`${environment.backendUrl + constants.config.getRoles}`, { headers: header });
  }


  updateCreditSignature(quantity) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.put(`${environment.backendUrl + constants.config.updateSignatureQuantity  }`, { 
      new_value: quantity
    }, { headers: header });
  }

  getCreditSignature() {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.get<any>(`${environment.backendUrl + constants.config.getSignatureQuantity}`, { headers: header });
  
  }

}
