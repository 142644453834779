import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertService } from '../alert.service';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit {

  @Output('response') response = new EventEmitter<Object>()
  public alert = {
    open: false,
    message: '',
    level: 'warning',
  }
  public msgIcon = {
    type: 'warning',
    color: '#ffb042',
    colorcont: '#ffff00'
  }
  public btn = true;
  public loading: boolean;
  public btn_hover = false;


  constructor(
    private _alert: AlertService
  ) { }

  ngOnInit() {
    this._alert.getOpen().subscribe(value => {
      this.btn = true;
      this.alert = value
      switch (value.level) {
        case 'warning':
            this.loading = false;

          this.msgIcon = {
            type: 'info-circle',
            color: '#000000',
            colorcont: '#ffff00'
          };
          break;
        case 'danger':
            this.loading = false;

          this.msgIcon = {
            type: 'times-circle',
            color: '#000000',
            colorcont: '#ffff00'
          };
          break;
        case 'success':
          this.loading = false;

          this.msgIcon = {
            type: 'check-circle',
            color: '#000000',
            colorcont: '#ffff00'
          };
          this.btn = false;
          setTimeout(() => {
            this.alert.open = false
          }, 2500);
          break;
        case 'loading':
          this.loading = true;
          this.msgIcon = {
            type: '',
            color: '',
            colorcont: ''
          }
          this.btn = false;
          break;
      }
    })
  }


  public emitResponse() {
    this.response.emit({
      ok: true,
      message: this.alert.message
    })
  }
}
