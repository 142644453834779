<header class="demo-header">
    <div (window:resize)="FuntionResize($event)" class="container">
        <div class="flex bar position-fixed">
            <div class="flex">
                <div class="img-container" style="width: 6vh; margin-right: .5rem;">
                    <i class="fas fa-bars" style="font-size: 150%;" (click)="_toggleSidebar()"></i>
                </div>
                <div *ngIf="!movile" class="img-container" style="display: flex; align-items: center;">
                    <img src="../../../assets/img/Logo.png" alt="" srcset="">
                </div>
                <!-- <div *ngIf="movile" class="img-container" style="width: 6vh; margin-right: .5rem;">
                    <img src="../../../assets/img/logo-short.png" alt="" srcset="">
                </div> -->
                
            </div>
            <div *ngIf="!pfn" class="flex" name="customer" (click)="close()">
                <div style="cursor: pointer;">
                    Salir
                </div>
                <div class="img-container" style="width: 9px;">
                    <i class="fas fa-sign-out-alt" style="font-size: 150%;"></i>
                </div>
                
            </div>
            <div *ngIf="pfn" class="flex" name="customer">
                <div class="dropdown dropleft">
                    <i class="fas fa-user-tie" style="font-size:180%;" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" style="cursor: pointer; margin-top: 10px; font-size: larger;" (click)="openModal(template)"><i class="fas fa-user" style="margin-right: 3px;"></i>Perfil</a>
                        <a class="dropdown-item" style="cursor: pointer; margin-top: 10px; font-size: larger;" (click)="close()"><i class="fas fa-sign-out-alt" style="margin-right: 3px;"></i>Salir</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<ng-sidebar-container>
            <ng-sidebar
                [(opened)]="_opened"
                [mode]="mode()"
                [dockedSize]="'50px'"
                [autoCollapseHeight]="_autoCollapseHeight"
                [autoCollapseWidth]="_autoCollapseWidth"
                [closeOnClickOutside]="_closeOnClickOutside"
                [closeOnClickBackdrop]="_closeOnClickBackdrop"
                [showBackdrop]="_showBackdrop"
                [animate]="_animate"
                [trapFocus]="_trapFocus"
                [autoFocus]="_autoFocus"
                [ariaLabel]="'My sidebar'">
                <br>
        
                <div class="xd" class="col-12">
                        <ul class="list-unstyled components" style="padding-top: 40px;">
                            <!-- <li *ngIf="pfn" style="text-align: center; cursor:pointer" (click)="openModal(template)">
                                <i class="fas fa-user-tie" style="font-size:50px;"></i>
                                <h6><strong>MI PERFIL</strong></h6>
                            </li> -->
                            <br>
                            <li class="li" style="cursor: pointer;">
                                <a (click)="home()"><i class="fas fa-home" style="margin: 5px; cursor: pointer;"></i>Dashboard</a>    
                            </li>
                            <div class="request" *ngIf="!pfn">
                                <li class="li" style="cursor: pointer;" *ngIf="user_rol == 'ADMINISTRATOR'">
                                    <a  (click)="users()"><i class="fas fa-user" style="margin: 5px; cursor: pointer;"></i>Usuarios</a>
                                </li>
                                <li class="li" style="cursor: pointer;">
                                    <a (click)="requests()" ><i class="far fa-arrow-alt-circle-down" style="margin: 5px; cursor: pointer;"></i>Descarga de Solicitudes</a>
                                </li>
                                <li class="li" style="cursor: pointer;">
                                    <a (click)="requests_updates()"><i class="fas fa-clipboard-list" style="margin: 5px; cursor: pointer;"></i>Actualizacion de Solicitudes</a>
                                </li>
                                <li class="li" style="cursor: pointer;">
                                    <a (click)="historical_updates()" ><i class="fas fa-file-alt" style="margin: 5px; cursor: pointer;"></i>Historico de Actualizaciones</a>
                                </li>
                                <li class="li" style="cursor: pointer;">
                                    <a (click)="historical_requests()" > <i class="fas fa-history" style="margin: 5px; cursor: pointer;"></i>Historico de Solicitudes</a>
                                </li>
                                <li class="li" style="cursor: pointer;">
                                    <a (click)="signature()" > <i class="fas fa-signature" style="margin: 5px; cursor: pointer;"></i>EasyCredit</a>
                                </li>
                                <li class="li" style="cursor: pointer;">
                                    <a (click)="credits_summary()" > <i class="fas fa-credit-card" style="margin: 5px; cursor: pointer;"></i>Resumen de Créditos</a>
                                </li>
                                <li class="li" style="cursor: pointer;">
                                    <a (click)="documentation_credits()" > <i class="fas fa-file-pdf" style="margin: 5px; cursor: pointer;"></i>Documentos de Créditos</a>
                                </li>
                                
                            </div>
                            <!-- Menús para PFN -->
                                
                            <div class="pfn" *ngIf="pfn">
                                <li *ngIf="root" class="li" style="cursor: pointer;">
                                    <a (click)="users_administration()"><i class="fas fa-users" style="margin: 5px; cursor: pointer;"></i>Administracion Usuarios</a>
                                </li>
                                <li *ngIf="root" class="li" style="cursor: pointer;">
                                    <a (click)="amount()" > <i class="fas fa-sort-numeric-up" style="margin: 5px; cursor: pointer;"></i>Administracion Montos</a>
                                </li>
                                <li *ngIf="root" class="li" style="cursor: pointer;">
                                    <a (click)="reason()" > <i class="fas fa-list-ol" style="margin: 5px; cursor: pointer;"></i>Administracion Motivos</a>
                                </li>
                                <li *ngIf="report" class="li" style="cursor: pointer;">
                                    <a (click)="release()" > <i class="fas fa-feather-alt" style="margin: 5px; cursor: pointer;"></i>Liberacion PFN</a>
                                </li>
                                <li class="li" style="cursor: pointer;">
                                    <a (click)="reports()"><i class="fas fa-chart-pie" style="margin: 5px; cursor: pointer;"></i>Informes</a>
                                </li>
                            </div>
                            <!--<li style="position: absolute; bottom: 0;">
                                <a (click)="close()"><i class="fas fa-power-off" style="margin: 5px;"></i>Cerrar Sesion</a>
                            </li>-->


                            <!-- Menú desplegable para pfn -->
                            <!-- <accordion class="li" [isAnimated]="true">
                                <accordion-group class="li" >
                                    <span accordion-heading>
                                        <i class="fas fa-clipboard-list li"></i> PFN's
                                    </span>
                                    <li class="li" style="cursor: pointer;">
                                        <a (click)="users_administration()"><i class="fas fa-users" style="margin: 5px; cursor: pointer;"></i>Administracion Usuarios</a>
                                    </li>
                                    <li class="li" style="cursor: pointer;">
                                        <a (click)="amount()" ><i class="fas fa-money-bill-alt" style="margin: 5px; cursor: pointer;"></i>Administracion Montos</a>
                                    </li>
                                    <li class="li" style="cursor: pointer;">
                                        <a (click)="reason()" > <i class="fas fa-list-ol" style="margin: 5px; cursor: pointer;"></i>Administracion Motivos</a>
                                    </li>
                                    <li class="li" style="cursor: pointer;">
                                        <a (click)="release()" > <i class="fas fa-feather-alt" style="margin: 5px; cursor: pointer;"></i>Liberacion PFN</a>
                                    </li>
    
                                    <li class="li" style="cursor: pointer;">
                                        <a (click)="reports()"><i class="fas fa-chart-pie" style="margin: 5px; cursor: pointer;"></i>Informes</a>
                                    </li>
                                </accordion-group>
                            </accordion>
                                -->
                        </ul>
                        
                </div>
            </ng-sidebar>

            <div ng-sidebar-content>
                
        
                <section class="demo-contents">
                    <router-outlet></router-outlet>
                </section>
            </div>
    </ng-sidebar-container>
    
    <ng-template #template>
        <div class="row justify-content-center" style="padding: 20px;">
            <h4 class="modal-title pull-left text-center" style="font-weight: bold; padding-top: 20px;"> <i class="fas fa-user" style="margin-right: 3px;"></i>Mi Perfil</h4>
                
            <div class="col-3" style="text-align: center; padding-top: 90px; padding: inherit;">
                <img src="../../../assets/img/image3.png" class="image_rotate" alt="" srcset="">
            </div> 
            <div class="col-9">
                <div class="modal-body">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12">
                                <label class="inputDialog"><strong>Usuario:</strong></label>
                            </div>
                            <label>{{user_data.user_name}}</label> 
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12">
                                <label class="inputDialog"><strong>Correo:</strong></label>
                            </div>
                            <label>{{user_data.email}}</label> 
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12">
                                <label class="inputDialog"><strong>Rol:</strong></label>
                            </div>
                            <label>{{user_data.rol}}</label> 
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12">
                                <label class="inputDialog"><strong>Contraseña:</strong></label>
                            </div>
                            <label (click)="openModal2(template2)" style="cursor: pointer; color: rgb(2 115 212);">Cambiar Contraseña</label> 
                        </div>
                        
                    <!--     <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
                        -->
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="!movile" class="col-3" style="text-align: center; padding-top: 90px;">
                <i class="fas fa-user-tie" style="font-size:120px; padding: 0;"></i>
            </div> -->
            
        </div>
    </ng-template>


    <ng-template #template2>
        <div class="row justify-content-center" style="padding: 20px;">
            <h5 class="modal-title pull-left text-center" style="font-weight: bold; padding-top: 20px;">Cambiar Contraseña</h5>
            <div class="col-12">
                <div class="modal-body">
                    <form #f="ngForm" (ngSubmit)="modalOK(f, template3)">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Contraseña Actual</label>
                            <input type="password" class="form-control" name="f_password" [(ngModel)]="datos.f_password" minlength="6" #f_password="ngModel" required placeholder="Password">
                            <div *ngIf="f_password.touched && !f_password.valid">
                                <div style="color: rgb(121, 75, 22);" *ngIf="f_password.errors.required">Este Campo es obligatorio</div>
                                <div style="color: rgb(121, 75, 22);" *ngIf="f_password.errors.minlength">La contraseña debe ser de minimo 6 caracteres</div>
                            </div>
                            <small [hidden]="movile" id="emailHelp" class="form-text text-muted">Debe ingresar su contraseña actual para confirmar su identidad.</small>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Nueva Contraseña</label>
                            <input type="password" class="form-control" name="password" [(ngModel)]="datos.password" minlength="6" #password="ngModel" required placeholder="Password">
                            <div *ngIf="password.invalid && (f.submitted || password.touched)">
                                <div style="color: rgb(121, 75, 22);" *ngIf="password.errors.required">Este Campo es obligatorio</div>
                                <div style="color: rgb(121, 75, 22);" *ngIf="password.errors.minlength">La contraseña debe ser de minimo 6 caracteres</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Confirmar Contraseña</label>
                            <input type="password" class="form-control" pattern="{{ password.value }}" name="c_password" [(ngModel)]="datos.c_password" minlength="6" #c_password="ngModel" required placeholder="Password">
                            <div *ngIf="c_password.invalid && (f.submitted || c_password.touched)">
                                <div style="color: rgb(121, 75, 22);" *ngIf="c_password.errors.required">Este Campo es obligatorio</div>
                                <div style="color: rgb(121, 75, 22);" *ngIf="c_password.errors.minlength">La contraseña debe ser de minimo 6 caracteres</div>
                                <div style="color: rgb(121, 75, 22);" *ngIf="c_password.errors.pattern"> La contraseña y confirmación no coinciden.</div>
                            </div>
                        </div>
                        <br/>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" (click)="closeModal(2, template)" class="btn btn-primary btn-modal">Cancelar</button>
                                </div>
                                <div class="col-6">
                                    <button type="submit" class="btn btn-dark btn-modal" style="background-color: black; color: white;">Actualizar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-template>

        <!-- detalles del modal OK-->
<ng-template #template3>
    <div class="modal-content" id="modal-details"  style="width: 100%; background-color: initial; border: 0; border-radius: 20px;">
        <div class="row" style="padding-left: 20px; padding-right: 20px;">
            <div class="card-body" style="padding: 4%;">
                <div class="row">
                    <div class="col-10">
                    </div>
                    <div class="col-2">
                        <h4 class="col-11" style="margin-bottom: 20px;">
                            <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal(3)">
                                <span aria-hidden="true" style="font-size: 40px;" class="visually-hidden">&times;</span>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h4 class="col-12" style="padding-left: 20px; padding-right: 20px; text-align: center;">
                            <b>Contraseña Actualizada</b>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="col-12" style="color: black; text-align: center; padding: 0px; padding-left: 40px; padding-right: 40px; ">
                            <small>Su contraseña se ha Actualizado de manera exitosa</small>
                        </p>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-12">
                        <div class="form-row">
                            <div class="form-group col-md-3 col-xs-6">
                            </div>
                            <div class="form-group col-md-6 col-xs-6">
                                <button style="margin-top: 8px; width: 100%; background-color: #000000; color: #ffff;" (click)="closeModal(3)" type="button" class="btn btn-black">Confirmar</button>
                            </div>
                            <div class="form-group col-md-3 col-xs-6">
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</ng-template>
<!--<div (window:resize)="FuntionResize($event)" class="container">
    <div class="flex bar position-fixed">
        <div class="flex" (click)="_toggleSidebar()">
            <div *ngIf="!movile" class="img-container">
                <img src="../../../assets/img/Logo.png" alt="" srcset="">
            </div>
            <div *ngIf="movile" class="img-container" style="width: 6vh; margin-right: .5rem;">
                <img src="../../../assets/img/logo-short.png" alt="" srcset="">
            </div>
        </div>
        <div class="flex" name="customer">
            Pedro Gómez
            
        </div>
    </div>
    
    <div class="row" style="background-color: white;">
        <div class="col-2">
            <nav id="sidebar">
                <ul class="list-unstyled components" style="padding-top: 100px;">
                    <br>
                    <li>
                        <a (click)="home()"><i class="fas fa-home" style="margin: 5px;"></i>Dashboard</a>    
                    </li>
                    <li>
                        <a (click)="users()"><i class="fas fa-user" style="margin: 5px;"></i>Usuarios</a>
                    </li>
                    <li>
                        <a (click)="requests()" ><i class="far fa-arrow-alt-circle-down" style="margin: 5px;"></i>Descarga de Solicitudes</a>
                    </li>
                    <li>
                        <a (click)="requests_updates()"><i class="fas fa-clipboard-list" style="margin: 5px;"></i>Actualizacion de Solicitudes</a>
                    </li>
                    <li>
                        <a (click)="historical_updates()" ><i class="fas fa-file-alt" style="margin: 5px;"></i>Historico de Actualizaciones</a>
                    </li>
                </ul>
            </nav> 
        </div>
        <div class="col-10">
            <router-outlet></router-outlet>
        </div>
        
        </div>
    
</div>
-->