<div class="img-overlay">
    <div class="overlay">
        <div class="login-container">
            <div class="img-container">
                <img src="../../../assets/img/logo-short.png" alt="Logo Bavaria">
            </div>
            <form [formGroup]="form_login" (ngSubmit)="login()">
                <input type="email" placeholder="Ingrese su Email" formControlName="user" spellcheck="false">
                <input type="password" placeholder="Ingrese Su Contraseña" formControlName="password" spellcheck="false">
                <button type="submit">INGRESAR</button>
            </form>
        </div>
    </div>
</div>
<footer>
    <p class="text-left footer-text-one">
        <span> Centro de Experiencia al Cliente <br>
            Comunícate con nosotros llamando a la línea telefónica
            <span class="font-Raleway-b"><a href="tel:2755505">2755505</a></span>
            en Bogotá y al <br>
            <span class="font-Raleway-b"><a href="tel:018000526555">01-8000-526-555</a></span>
            en el resto del país o a través del <a
                href="https://3h11.la2-c2-ord.salesforceliveagent.com/content/s/chat?language=es&org_id=00D40000000MxOB&deployment_id=572400000008Ols&sid=854e4cf8-4138-4813-a43e-25215724a37b#deployment_id=572400000008Ols&org_id=00D40000000MxOB&button_id=573400000008Om2&session_id=854e4cf8-4138-4813-a43e-25215724a37b"
                target="_blank"> chat </a> en línea que encontrarás ingresando a la Tienda Bavaria
        </span>
    </p>
    <p class="text-left footer-text-two">Horarios de atención: <br>
        Lunes a Viernes de 8:00 AM a 5:00 PM <br>Sabados
        de 8:00 AM a 4:00 PM</p>
    <p class="text-right footer-text-three">
        <img src="assets/img/LOGOBAVARIA-INVERTIDO.png" width="40px">
        <br> Bavaria 2018
        <br> Todos los derechos reservados</p>
</footer>