import { Component } from '@angular/core';
import { slider } from './animaciones';
import { RouterOutlet } from '@angular/router';
import { AlertService } from './shared-components/alert.service';
import { AlertService2 } from './shared-components/alert2.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slider,
    // fader
  ]
})
export class AppComponent {
  title = 'admin-bess';

  public alert = {
    open: false,
    message: '',
    level: ''
  }

  public alert2 = {
    open: false,
    message: '',
    level: ''
  }

  constructor(
    private _alert: AlertService,
    private _alert2: AlertService2
  ) { }

  ngOnInit(): void {
    this._alert.getOpen().subscribe((value) => this.alert = value)
    this._alert2.getOpen().subscribe((value) => this.alert2 = value)
  
  }

  public prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  public toggleAlert(e) {
    if (e.ok) {
      this.alert.open ? this.alert.open = false : this.alert.open = true;
    }
  }

  public toggleAlert2(e) {
    if (e.ok) {
      this.alert2.open ? this.alert2.open = false : this.alert2.open = true;
    }
  }
}