import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface Alert2 {
  open: boolean,
  message: string,
  level: string,
  text?: string;
}

@Injectable({
  providedIn: 'root'
})

export class AlertService2 {

  public alert = {
    open: false,
    message: '',
    level: 'warning',
  }
  private alertOpen: BehaviorSubject<Alert2> = new BehaviorSubject({
    open: false,
    message: '',
    level: 'warning',
  })


  constructor(
    private _http: HttpClient,
  ) { }

  public setOpen(value: Alert2) {
    this.alert = value
    this.alertOpen.next(value)
  }
  public getOpen() {
    return this.alertOpen.asObservable()
  }

  public networkOn() {
    return this._http.get('https://storage.googleapis.com/bavaria-downloads/tiny-image.png');
  }

}
