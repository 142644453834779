import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { constants } from '../../../environments/constants';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( 
    public httpclient: HttpClient, 
    private storage: StorageService,
    private _router: Router,
     ) 
  {

  }
  /*
  * Servicio de Login
  */
  login(data) {
    return this.httpclient.post<any>(`${environment.backendUrl + constants.config.login}`, data);
  }

  /*
  * Servicio de Logout
  */
  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this._router.navigate(['/login']);
    return true;
    
  }

  /*
  * Servicio de CheckSession
  * Revisa si existe un usuario logueado respecto a un token de session
  */
  checksession() {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    });
    return this.httpclient.get<any>(`${environment.backendUrl + constants.config.checksession}`, { headers: header });
  }

  public ischeckSesion(): boolean {
    var st: boolean;
    this.checksession().subscribe((res) => {
      if (res.status == 401) {
        st = false;
      }
      if (res.status == 200) {
        st = true;
      }
    },
    (err) => {
        st = false;
    });
    return st;
}



}
