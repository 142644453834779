import {
	trigger,
	transition,
	style,
	query,
	group,
	animate,
} from '@angular/animations';

//fader
//

export const fader = trigger('routerAnimations', [
	transition('* <=> *', [
		query(':enter, :leave', [
			style({
				position: 'absolute',
				left: 0,
				width: '100%',
				opacity: 0,
				transform: 'scale(0) translateY(100%)'
			}),
		], { optional: true }),
		query(':enter', [
			animate('600ms ease',
				style({ opacity: 1, transform: 'scale(1) translateY(0)' })
			)
		], { optional: true })
	])
]);


export function slideToLeft(obj) {
	return [
		query(':enter, :leave', [
			style({
				position: 'absolute',
				top: obj.top,
				left: 0,
				width: '100%',
				height: '100%',
				overflow: 'hidden'
			})
		], { optional: true }),
		query(':enter', [
			style({
				left: '-100%'
			})
		], { optional: true }),
		group([
			query(':leave', [
				animate('600ms ease', style({ left: '100%' }))
			], { optional: true }),
			query(':enter', [
				animate('600ms ease', style({ left: '0%' }))
			], { optional: true })
		])
	]
}
export function slideToRight(obj) {
	return [
		query(':enter, :leave', [
			style({
				position: 'absolute',
				top: obj.top,
				right: 0,
				width: '100%',
				height: '100%',
				overflow: 'hidden'

			})
		], { optional: true }),
		query(':enter', [
			style({
				right: '-100%'
			})
		], { optional: true }),
		group([
			query(':leave', [
				animate('600ms ease', style({ right: '100%', overflow: 'hidden' }))
			], { optional: true }),
			query(':enter', [
				animate('600ms ease', style({ right: '0%', overflow: 'hidden' }))
			], { optional: true })
		])
	]
}


export const slider = trigger('routerAnimations', [
	transition('isRight => isLeftTop', slideToLeft({top: '0%'})),
	
	transition('* => isLeft', slideToLeft({top: '0%'})),
	transition('* => isRight', slideToRight({top: '0%'})),
	transition('isRight => *', slideToLeft({top: '0%'})),
	transition('isLeft => *', slideToRight({top: '0%'}))
])