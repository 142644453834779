import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinerComponent } from './spiner/spiner.component';
import { AlertComponent } from './alert/alert.component';
import { Alert2Component } from './alert2/alert2.component';



@NgModule({
  declarations: [SpinerComponent, AlertComponent, Alert2Component],
  exports: [SpinerComponent, AlertComponent],
  imports: [
    CommonModule
  ]
})
export class SharedComponentsModule { }
