import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { StorageService } from '../services/storage/storage.service';
import { AlertService } from '../shared-components/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public auth: AuthService,
    public router: Router,
    public storage: StorageService,
    public _alert: AlertService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const peticion = sessionStorage.getItem('access_token')
    if (peticion === null  || peticion === undefined)  {
      this.router.navigate(['/login']);
      sessionStorage.clear();
      localStorage.clear();
      return false;
    }
    this.auth.checksession().subscribe((res: any) => {
      if (res.status == 'EXPIRED_TOKEN') {
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['/login']);
        return false;
      }
    }, error => {
      if (error.error.status == 'EXPIRED_TOKEN'){
        this._alert.setOpen({ open: true, message: 'Su sesión ha finalizado', level: 'danger' });
      }
      if (error.error.message == 'INVALID_CREDENTIALS' || error.error.message == 'NO_AUTH_CREDENTIALS'){
        this._alert.setOpen({ open: true, message: 'Sus credenciales son invalidas', level: 'danger' });
      }
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/login']);
      return false;
    });
    return true;

  }
}
