
export const  constants = {

    config: {
        login: 'admin/login',
        checksession: 'admin/check-session',
        logout: 'admin/logout',
        getUsers: 'admin/users',
        saveUsers: 'admin/user',
        deleteUsers: 'admin/user',
        getUserbyId: 'admin/user',
        updateUsers: 'admin/user',
        getRoles: 'admin/roles',
        filterHistorical: 'credits/admin/filter?',
        creditCustomer: 'credits/admin/customer',
        Creditsdefinitions: 'credits/admin/definitions',
        autoCompleteClient: 'admin/customers/autocomplete?',
        customerById: 'admin/customers',
        getRequests: 'customer_requests/admin/dashboard_requests?order_by=creation_date:desc',
        lineCharts: 'customer_requests/admin/group_type_monthly',
        pieCharts: 'customer_requests/admin/group_status_last_week',
        pendingRequest: 'customer_requests/admin/pending',
        dowlandRequest: 'customer_requests/admin/download_pending_requests',
        getHistoricalUpdate: 'customer_requests/admin/excel_request_analysis_all?order_by=creation_date:desc',
        addExcelRequest: 'customer_requests/admin/add_excel_request_analysis',
        getHistoricalRequest: 'customer_requests/admin/customer_request_all',
        saveTermAndCond: 'credits/admin/create_personal_tyc',
        saveQuota: 'credits/admin/create_quota_tyc',
        dowlandHistoricalRequest: 'customer_requests/admin/customer_request_all/download',
        updateSignatureQuantity: 'admin/config/FAST_CREDITS_AMOUNT',
        getSignatureQuantity: 'admin/config',
        definitions_pfn: 'admin/pfn/data_definitions',
        getReasons: 'admin/pfn/return_reasons',
        deleteReason: 'admin/pfn/return_reason',
        getAmounts: 'admin/pfn/assignment_rules',
        getDefinitionsPfn: 'admin/pfn/definitions',
        filterUsers: 'admin/users/filter',
        filterPFN: 'admin/pfn/filter',
        getPFNDetails: 'admin/pfn',
        breakfreePFN: 'admin/pfn',
        reasonCharts: 'admin/pfn/statistics/items_grouped_reason',
        pfnCharts: 'admin/pfn/statistics/request_grouped_monthly',
        updatePassword: 'admin/change-password',
        getPFNReports: 'admin/pfn/boards',
    }
};
