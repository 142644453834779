import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared-components/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form_login: FormGroup;
  data: any = {
    user: null,
    password: null
  }
  
  constructor(
    private _alert: AlertService,
    private _router: Router,
    private fb: FormBuilder,
    private _auth: AuthService,
  ) { 
    this.form_login = fb.group({
      user: [null, Validators.required],
      password: [null, Validators.required]
  });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('access_token') != null){
      this._router.navigate(['/home']);
    }
  }

  public login(){
    if (this.form_login.value.user == null || this.form_login.value.password == null){
      this._alert.setOpen({ open: true, message: 'Debes ingresar los datos de sesion', level: 'danger' });
      return
    }
    let datos = {
      email: this.form_login.value.user,
      password: this.form_login.value.password
    };
    this._auth.login(datos).subscribe(
      (res) => {
        if (res.status == 200) {
            sessionStorage.setItem('access_token',res.data.auth.access_token);
            sessionStorage.setItem('resources_token',res.data.auth.resources_token);
            this._router.navigate(['/home']);
        } else {
          this._alert.setOpen({ open: true, message: 'Código o Identificación Errado, por favor contactar a su texsail de la regional o al administrador de la plataforma', level: 'danger' });
        }
      },
      (error) => {
        if (error.error.localized_code == 'INVALID_CREDENTIALS'){
          this._alert.setOpen({ open: true, message: 'Código o Identificación Errado, por favor contactar a su texsail de la regional o al administrador de la plataforma', level: 'danger' });
        
          //this._alert.setOpen({ open: true, message: 'Código o Identificación Errado', level: 'danger' });
        }
        if (error.error.localized_code == 'DISABLED_USER'){
          this._alert.setOpen({ open: true, message: 'Este usuario no se encuentra activo', level: 'danger' });
        }
        if (error.error.localized_code == 'Invalid(email)'){
          this._alert.setOpen({ open: true, message: 'Ingrese un email valido', level: 'danger' });
        }
        if (error.error.status == 500){
          this._alert.setOpen({open: true, message: 'Server Error', level: 'danger'});
        }
      }
    );
    
  }

}
